import $ from "jquery";

const elMobile = {
  toc: ".mi-mobile-nav",
  tocItem: ".mi-mobile-nav .elementor-toc__list-item-text-wrapper",
  tocHeader: ".mi-mobile-nav .elementor-toc__header",
  tocButton: ".mi-mobile-nav .elementor-toc__toggle-button--collapse",
};

const elDesktop = {
  toc: ".mi-toc",
  tocItem: ".mi-toc .elementor-toc__list-item-text-wrapper",
  tocHeader: ".mi-toc .elementor-toc__header",
  tocButton: ".mi-toc .elementor-toc__toggle-button--collapse",
  tocText: ".mi-toc .elementor-toc__list-item-text",
};

const initMobile = () => {
  $(document).on("click", elMobile.tocItem, function () {
    $(elMobile.tocButton).click();
  });
};

const initDesktop = () => {
  $(document).on("click", elDesktop.tocItem, function () {
    $(elDesktop.tocItem).css("background-color", "unset");
    $(elDesktop.tocItem).css("color", "unset");
    $(this).css("background-color", "#1ed6c2");
    $(this).css("color", "#ffffff");
  });
};

try {
  initMobile();
  initDesktop();
} catch (error) {
  console.error(error);
}
